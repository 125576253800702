@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@1,200&family=PT+Sans+Narrow&family=Play&family=Rubik+Glitch&display=swap");
body {
  margin: 0;

  font-family: "PT Sans Narrow", sans-serif, "Play", sans-serif, "Nunito Sans",
    sans-serif, "Rubik Glitch", cursive;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "PT Sans Narrow", sans-serif, "Play", sans-serif, "Nunito Sans",
    sans-serif, "Rubik Glitch", cursive;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: "PT Sans Narrow";
}
input {
  border: none;
  background-color: transparent;
  font-size: 16px;
}
input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
h2 {
  font-weight: 100;
}
