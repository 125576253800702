.round {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid #00457c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.round:hover {
  background: white;
}
@media (max-width: 550px) {
  .round:hover {
    background: transparent;
  }
}
